<template>
  <div class="centerView DeliveryTable">
    <div style="position: relative">
      <a-tabs v-model:activeKey="activeKey" size="small" class="DeliveryTable-table">
        <a-tab-pane v-for="item in tabsPaneList" :key="item.key" :tab="`${t(item.tab)}(${item.num})`"></a-tab-pane>
      </a-tabs>
      <div v-show="activeKey == '1' && table1dataSource.length" class="flexR" style="position: absolute; top: -10px; right: 0">
        <div class="operation-button">
          <a-space :size="16">
            <a-button type="primary" size="large" class="padding-horizontal-12" style="margin-right: 42px" @click="printStockClick">{{
              t('orderManageDetail.DeliveryTable.printTheStockList')
            }}</a-button>
            <a-button type="primary" size="large" class="padding-horizontal-12" style="margin-right: 42px" @click="completionFn" :loading="completionSubmitting">{{
              t('orderManageDetail.DeliveryTable.completion')
            }}</a-button>
            <!-- <a-button type="primary" size="large" class="padding-horizontal-12">导入发货明细</a-button>
            <a-button type="primary" size="large" class="padding-horizontal-12">导出代发货明细</a-button> -->
            <div class="form-item-button gray padding-horizontal-20" @click="importFileFn">{{ t('orderManageDetail.DeliveryTable.import') }}</div>
            <input type="file" ref="importInputRef" accept=".xls,.xlsx" @change="onImportInputChange" style="display: none" />
            <div class="form-item-button gray padding-horizontal-20" @click="exportOrderFn">
              <LoadingOutlined v-if="exportOrderSubmitting" />
              {{ t('orderManageDetail.DeliveryTable.download') }}
            </div>
          </a-space>
        </div>
      </div>
    </div>

    <a-table
      v-if="!tableLoading && activeKey == '1'"
      :dataSource="table1dataSource"
      :columns="columns"
      :loading="tableLoading"
      :pagination="false"
      row-key="id"
      size="small"
      class="ant-table-striped"
      :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      :scroll="{ x: 1300 }"
    >
      <template #productPicTemplate="{ text }">
        <ImgGallery v-if="text" :urls="[text]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
        <span v-else>-</span>
      </template>
      <template #productName="{ text }">
        {{ text || '-' }}
      </template>
      <template #sku="{ text }">
        {{ text || '-' }}
      </template>
      <template #productParams="{ text }">
        <!-- <ImgGallery /> -->
        {{ (JSON.parse(text || '{}') || {}).color_name }} / {{ (JSON.parse(text || '{}') || {}).size_name }}
      </template>
      <template #manufacturingNo="{ text }">
        {{ text || '-' }}
      </template>
      <template #dissentQuantity="{ text }">
        {{ text || 0 }}
      </template>
      <template #deliveryQuantity="{ text }">
        {{ text || 0 }}
      </template>
      <template #dissentQuantityTemp="{ record }">
        <!-- :disabled="!(record.dissentQuantity - record.deliveryQuantity)" -->
        <a-input
          v-model:value="record.dissentQuantityInput"
          allowClear
          size="large"
          :suffix="t('orderManageDetail.DeliveryTable.table.unit')"
          style="width: 140px"
          :placeholder="`${t('orderManageDetail.ReceiveOrderTable.table.pleaseInput')}${record.dissentQuantity - record.deliveryQuantity}`"
        />
        <div v-show="record.dissentQuantityInput > record.dissentQuantity - record.deliveryQuantity" style="font-size: 12px; color: #f53838">
          {{ t('orderManageDetail.DeliveryTable.table.dissentQuantityTips1') }}{{ record.dissentQuantity - record.deliveryQuantity }}{{ t('orderManageDetail.DeliveryTable.table.unit') }}
        </div>
        <div v-show="record.dissentQuantityInput < 0" style="font-size: 12px; color: #f53838">{{ t('orderManageDetail.DeliveryTable.table.dissentQuantityTips2') }}</div>
      </template>
      <template #quantityIncomGoods="{ record }">
        {{ record.receiptQuantity || '--' }}
      </template>
      <template #cancelQuantity="{ record }">
        {{ record.cancelQuantity || '--' }}
      </template>
      <template #shippedQuantity="{ record }">
        <div>{{ record.dissentQuantityTemp }}</div>
      </template>
    </a-table>

    <div v-if="!tableLoading && activeKey == '2'">
      <template v-for="item in table2dataSource" :key="item.id">
        <div class="flexC" style="margin-bottom: 16px" v-if="item.shippingDetailBos && item.shippingDetailBos.length">
          <div class="order-code flexS">
            <span>
              <img src="@/assets/supplier/images/deliveryManage/order-code-icon.png" />
              #{{ item.shippingSn || '-' }}
            </span>
            <span>{{ t('orderManageDetail.DeliveryTable.shippingStatusLabel') }}：{{ t(shippingStatus.find((it) => it.value == item.status).label) || '-' }}</span>
          </div>
        </div>
        <div class="invoice-list" v-if="item.shippingDetailBos && item.shippingDetailBos.length">
          <a-space :size="16" style="padding-right: 16px">
            <div class="invoice-item flexTL" v-for="it in item.expressListBos" :key="it.id">
              <div class="time">
                {{ it.deliveryAt || '-' }}
                <span class="status" :style="{ color: it.status == 10 ? '#ff6700' : '' }" v-if="it.status">【{{ it.status && t(expressStatus.find((i) => i.value == it.status).label) }}】</span>
              </div>
              <span class="invoice-code">【{{ it.expressName || '-' }}】{{ it.expressCode || '-' }}</span>
            </div>
            <div class="invoice-add flexC" @click="addExpressFn(item)">
              <img class="icon" src="@/assets/supplier/images/deliveryManage/express-add-icon.png" />
              <span class="text">{{ t('orderManageDetail.DeliveryTable.expressAddLabel') }}</span>
            </div>
          </a-space>
        </div>
        <a-table
          :dataSource="item.shippingDetailBos"
          :columns="columns"
          :loading="tableLoading"
          :pagination="false"
          row-key="id"
          size="small"
          class="ant-table-striped"
          style="margin-bottom: 20px"
          :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
          :scroll="{ x: 1300 }"
        >
          <template #productPicTemplate="{ text }">
            <ImgGallery v-if="text" :urls="[text]" :img-width="39" :img-height="52" :show-img-num="1" :show-more="false" />
            <span v-else>-</span>
          </template>
          <template #productName="{ text }">
            {{ text || '-' }}
          </template>
          <template #sku="{ text }">
            {{ text || '-' }}
          </template>
          <template #productParams="{ text }">
            <!-- <ImgGallery /> -->
            {{ (JSON.parse(text || '{}') || {}).color_name || '-' }} / {{ (JSON.parse(text || '{}') || {}).size_name || '-' }}
          </template>
          <template #manufacturingNo="{ text }">
            {{ text || '-' }}
          </template>
          <template #dissentQuantity="{ record }">
            {{ record.deliveryQuantity || 0 }}
          </template>
          <template #receiptQuantity="{ record, text }">
            <a-space direction="vertical" :size="10">
              <span>{{ t('orderManageDetail.DeliveryTable.table.unReceive') }}：{{ record.deliveryQuantity - text || 0 }}</span>
              <span>{{ t('orderManageDetail.DeliveryTable.table.receive') }}：{{ text || 0 }}</span>
            </a-space>
          </template>
          <template #unqualifiedQuantity="{ record, text }">
            <a-space direction="vertical" :size="10">
              <span>{{ t('orderManageDetail.DeliveryTable.table.qualified') }}：{{ record.qualifiedQuantity || 0 }}</span>
              <span>{{ t('orderManageDetail.DeliveryTable.table.unqualified') }}：{{ text || 0 }}</span>
            </a-space>
          </template>
        </a-table>
      </template>
      <a-table
        v-if="!table2dataSource.length"
        :columns="columns"
        :pagination="false"
        row-key="id"
        size="small"
        class="ant-table-striped"
        style="margin-bottom: 20px"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :scroll="{ x: 1300 }"
      >
      </a-table>
    </div>

    <TableSkeleton v-if="tableLoading" />

    <AddExpressModal ref="AddExpressModalRef" v-model:modalVisible="modalVisible" v-model:submitting="submitting" @submit="(e) => createExpressFn(e)"></AddExpressModal>
    <!-- <pagination mt lable="" @change="onPageChange" :data="tableResult" @sizeChange="onSizeChange"></pagination> -->
  </div>
</template>

<script>
/* eslint-disable */
import { ref, reactive, watch } from 'vue'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { message } from 'ant-design-vue'
import { LoadingOutlined } from '@ant-design/icons-vue'
import { transformColumnsI18n, filterInitialColumnsFn } from '@/language'
import pagination from '@/components/pagination'
import TableSkeleton from '@/views/supplier/components/TableSkeleton'
import AddExpressModal from '@/views/supplier/components/AddExpressModal'
import ImgGallery from '@/views/supplier/components/ImgGallery'

import { exportShippingDetail, createShipping } from '@/api/supplier/orderManagement/orderManage'
import { createExpress } from '@/api/supplier/orderManagement/deliveryManage'

export default {
  name: 'DeliveryTable',
  components: {
    pagination,
    TableSkeleton,
    ImgGallery,
    AddExpressModal,
    LoadingOutlined,
  },
  props: {
    tableLoading: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default() {
        return {}
      },
    },
    otherData: {
      type: Object,
      default() {
        return {}
      },
    },
    pageType: {
      type: String,
      default() {
        return ''
      },
    },
    tableType: {
      type: String,
      default() {
        return ''
      },
    },
    id: {
      type: Number,
    },
  },
  emits: ['onPageChange', 'onSizeChange', 'getData', 'exportOrderList', 'closeModal'],
  setup(props, { emit }) {
    const route = useRoute()
    const router = useRouter()
    // const pageType = route.query.type
    // const tableType = route.query.tableType
    // const id = route.query.id
    const pageType = props.pageType
    const tableType = props.tableType
    const id = props.id
    const { t, locale } = useI18n()

    const isEdit = ref(false)

    /*  */
    const activeKey = ref(route.query.activeKey || '1')
    const tabsPaneList = ref([
      {
        index: 10,
        key: '1',
        tab: 'orderManageDetail.DeliveryTable.tabsPane.1',
        num: 0,
      },
      {
        index: 20,
        key: '2',
        tab: 'orderManageDetail.DeliveryTable.tabsPane.2',
        num: 0,
      },
    ])

    function getPromotionCouponCouponsData() {
      getPromotionCouponCoupons({})
        .then((res) => {
          if (res.code === 200) {
            tabsPaneList.value = tabsPaneList.value.map((item) => {
              item.num = res.data[item.index] || 0
              return item
            })
          } else {
            // message.error(res.msg)
          }
        })
        .catch(() => {
          // tableLoading.value = false;
          // setSearchLoading(false);
        })
    }
    // getPromotionCouponCouponsData();

    /* 待接订单状态 */
    const shippingStatus = [
      {
        label: 'orderManageDetail.DeliveryTable.shippingStatus.1',
        value: 0,
      },
      {
        label: 'orderManageDetail.DeliveryTable.shippingStatus.2',
        value: 10,
      },
      {
        label: 'orderManageDetail.DeliveryTable.shippingStatus.3',
        value: 20,
      },
      {
        label: 'orderManageDetail.DeliveryTable.shippingStatus.4',
        value: 30,
      },
      {
        label: 'orderManageDetail.DeliveryTable.shippingStatus.5',
        value: 40,
      },
    ]

    /* 快递状态 */
    const expressStatus = [
      {
        label: 'orderManageDetail.DeliveryTable.expressStatus.1',
        value: 10,
      },
      {
        label: 'orderManageDetail.DeliveryTable.expressStatus.2',
        value: 20,
      },
    ]

    /*  */
    const initialColumns = [
      {
        title: 'orderManageDetail.DeliveryTable.table.productPicTemplate',
        dataIndex: 'productPicTemplate',
        key: 'productPicTemplate',
        width: 70,
        slots: { customRender: 'productPicTemplate' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.manufacturingNo',
        dataIndex: 'manufacturingNo',
        key: 'manufacturingNo',
        width: 150,
        slots: { customRender: 'manufacturingNo' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.productParams',
        dataIndex: 'productParams',
        key: 'productParams',
        width: 150,
        slots: { customRender: 'productParams' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.dissentQuantity',
        dataIndex: 'dissentQuantity',
        key: 'dissentQuantity',
        width: 100,
        slots: { customRender: 'dissentQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.quantityIncomGoods',
        dataIndex: 'quantityIncomGoods',
        key: 'quantityIncomGoods',
        width: 100,
        slots: { customRender: 'quantityIncomGoods' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.cancelQuantity',
        dataIndex: 'cancelQuantity',
        key: 'cancelQuantity',
        width: 100,
        slots: { customRender: 'cancelQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.deliveryQuantity',
        dataIndex: 'deliveryQuantity',
        key: 'deliveryQuantity',
        width: 100,
        slots: { customRender: 'deliveryQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.dissentQuantityTemp',
        dataIndex: 'dissentQuantityTemp',
        key: 'dissentQuantityTemp',
        width: 180,
        slots: { customRender: 'dissentQuantityTemp' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.receiptQuantity',
        dataIndex: 'receiptQuantity',
        key: 'receiptQuantity',
        width: 150,
        slots: { customRender: 'receiptQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.unqualifiedQuantity',
        dataIndex: 'unqualifiedQuantity',
        key: 'unqualifiedQuantity',
        width: 150,
        slots: { customRender: 'unqualifiedQuantity' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.sku',
        dataIndex: 'sku',
        key: 'sku',
        width: 150,
        slots: { customRender: 'sku' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.productName',
        dataIndex: 'productName',
        key: 'productName',
        // width: 150,
        slots: { customRender: 'productName' },
      },
      {
        title: 'orderManageDetail.DeliveryTable.table.shippedQuantity',
        dataIndex: 'shippedQuantity',
        key: 'shippedQuantity',
        width: 100,
        slots: { customRender: 'shippedQuantity' },
      },
    ]

    const columns = ref([])
    watch(locale, () => {
      let keys = columns.value.map(({ key }) => key)
      columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, keys))
    })

    // const dataSource = ref(null);
    const table1dataSource = ref([])
    const table2dataSource = ref([])
    // const tableResult = ref(null);

    watch(
      () => props.data,
      (newValue) => {
        console.log('props.data newValue :>> ', newValue)
        let { purchaseOrderDetailList = [], shippingBos = [] } = newValue
        table1dataSource.value = purchaseOrderDetailList.map((item) => {
          item.dissentQuantityInput = ''
          item.dissentQuantityTemp = item.dissentQuantity - item.deliveryQuantity
          return item
        })
        table2dataSource.value = shippingBos
        tabsPaneList.value[0].num = table1dataSource.value.length
        // tabsPaneList.value[1].num = table2dataSource.value.length;
        tabsPaneList.value[1].num = table2dataSource.value.reduce((pre, cur) => ((pre += cur.shippingDetailBos.length), pre), 0)
      }
    )

    // function onPageChange(e) {
    //   filterForm.value.page = e;
    //   getTableData();
    //   emit('onPageChange')
    // }
    // function onSizeChange({ pageSize }) {
    //   filterForm.value.size = pageSize;
    //   filterForm.value.page = 1;
    //   getTableData();
    //   emit('onSizeChange')
    // }

    watch(
      () => activeKey.value,
      (newValue) => {
        // filterForm.value.state = tabsPaneList.value.find((it) => it.key == newValue).index;
        let filterKeys = []
        if (newValue == '1') {
          filterKeys = [
            'productPicTemplate',
            'manufacturingNo',
            'productParams',
            'dissentQuantity',
            'quantityIncomGoods',
            'deliveryQuantity',
            'sku',
            'shippedQuantity',
            'dissentQuantityTemp',
            'productName',
            'cancelQuantity',
          ]
        }
        if (newValue == '2') {
          filterKeys = ['productPicTemplate', 'manufacturingNo', 'productParams', 'dissentQuantity', 'receiptQuantity', 'unqualifiedQuantity', 'sku', 'productName']
        }
        // columns.value = initialColumns.filter((it) => !filterKeys.includes(it.key));
        columns.value = transformColumnsI18n(filterInitialColumnsFn(initialColumns, filterKeys))
        // search();
      },
      {
        immediate: true,
      }
    )

    // 添加快递
    const AddExpressModalRef = ref()
    const submitting = ref(false)
    const modalVisible = ref(false)
    const currentForm = ref({})

    function addExpressFn({ id, shippingSn, businessId }) {
      AddExpressModalRef.value.addExpress()
      currentForm.value = { shippingId: id, shippingSn, businessId }
    }

    const createExpressFn = (e) => {
      submitting.value = true
      createExpress({ ...e, ...currentForm.value })
        .then((res) => {
          submitting.value = false
          if (res.code === 200) {
            message.success(t('opSuccess'))
            modalVisible.value = false
            // getTableData();
            emit('getData')
            isEdit.value = true
          } else {
            // message.error(res.msg)
          }
        })
        .catch(() => {
          submitting.value = false
        })
    }

    // 备货完成，发货
    const completionSubmitting = ref(false)
    function completionFn() {
      if (table1dataSource.value.some(({ dissentQuantityInput, dissentQuantity, deliveryQuantity }) => dissentQuantityInput > dissentQuantity - deliveryQuantity)) return
      if (table1dataSource.value.some(({ dissentQuantityInput }) => dissentQuantityInput < 0)) return
      if (table1dataSource.value.every(({ dissentQuantityInput }) => !dissentQuantityInput)) return
      completionSubmitting.value = true
      let purchaseOrderDetailShippingList = table1dataSource.value
        .filter(({ dissentQuantityInput }) => dissentQuantityInput)
        .map(({ id, dissentQuantityInput }) => ({ purchaseOrderDetailId: id, dissentQuantity: dissentQuantityInput }))
      console.log(purchaseOrderDetailShippingList, 'purchaseOrderDetailShippingList------purchaseOrderDetailShippingList')
      createShipping({ purchaseOrderId: props.otherData.id, purchaseOrderDetailShippingList })
        .then((res) => {
          completionSubmitting.value = false
          if (res.code === 200) {
            message.success(t('opSuccess'))
            isEdit.value = true
            // router.back()
            emit('closeModal')
          } else {
            // message.error(res.msg)
          }
        })
        .catch(() => {
          completionSubmitting.value = false
        })
    }

    /*  */
    const XLSX = require('xlsx')
    const importInputRef = ref()
    function onImportInputChange(e) {
      // console.log('e :>> ', e);
      const files = e.target.files
      var vali = /\.(xls|xlsx)$/
      if (files.length <= 0) {
        //如果没有文件名
        importing.value = false
        return false
      } else if (!vali.test(files[0].name.toLowerCase())) {
        importing.value = false
        message.error(t('orderManageDetail.DeliveryTable.importTips1'))
        return false
      }
      const fileReader = new FileReader()
      fileReader.readAsBinaryString(files[0])
      fileReader.onload = (e) => {
        importing.value = false
        try {
          const data = e.target.result // data则是我们在excel表中获取的数据
          const xlsxData = XLSX.read(data, { type: 'binary', cellDates: true }) // 通过XLSX读取出来得到了一个 workdata 对象，下面详细介绍 workdata 对象中有些什么
          // console.log('xlsxData :>> ', xlsxData);
          importInputRef.value.value = '' // 处理完成 清空表单值
          formatXlsxData(xlsxData)
        } catch (e) {
          return false
        }
      }
    }
    function formatXlsxData(data) {
      const sheetNames = data.SheetNames //获取需要数据的表名
      const workSheet = data.Sheets[sheetNames] //workSheet 是该excel表格中的数据
      const workSheetJsonData = XLSX.utils.sheet_to_json(workSheet)
      // console.log('workSheetJsonData :>> ', workSheetJsonData);
      if (!workSheetJsonData || !workSheetJsonData.length) {
        message.warning(t('orderManageDetail.DeliveryTable.importTips2'))
        return
      }
      // const idIndex = workSheetJsonData[0].findIndex((it) => it == 'ID');
      // const targetIndex = workSheetJsonData[0].findIndex((it) => it == '本次发货数量');
      // const workSheetJsonTableData = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
      setTable1dataSource(workSheetJsonData)
    }
    function setTable1dataSource(workSheetJsonData) {
      table1dataSource.value = table1dataSource.value.map((item, index) => {
        let temp = workSheetJsonData.find((it) => it['ID'] == item.id)
        if (temp) {
          item.dissentQuantityTemp = temp['本次发货数量']
        }
        return item
      })
    }
    const importing = ref(false)
    function importFileFn() {
      importing.value = true
      importInputRef.value.click()
    }

    /* 导出 */
    const exportOrderSubmitting = ref(false)
    function exportOrderFn() {
      if (exportOrderSubmitting.value) return
      exportOrderSubmitting.value = true
      emit('exportOrderList')
      // if (exportOrderSubmitting.value) return
      // exportOrderSubmitting.value = true
      // exportShippingDetail({ id: props.otherData.id })
      //   .then((res) => {
      //     exportOrderSubmitting.value = false
      //     if (res.code === 200) {
      //       message.success(t('opSuccess'))
      //     } else {
      //       // message.error(res.msg)
      //     }
      //   })
      //   .catch(() => {
      //     exportOrderSubmitting.value = false
      //   })
    }
    const loadingTypeFun = () => {
      exportOrderSubmitting.value = false
    }
    onBeforeRouteLeave((to, from, next) => {
      to.meta.isEdit = isEdit.value
      to.meta.isBack = true
      next()
    })

    //打印备货单
    const printStockClick = () => {
      // router.push({
      //   path: '/printDeliver',
      //   query: { id },
      // })
      window.open(`${window.location.origin}/printDeliver?id=${id}`)
    }
    return {
      t,
      pageType,
      tableType,
      activeKey,
      tabsPaneList,
      shippingStatus,
      expressStatus,
      columns,
      table1dataSource,
      table2dataSource,
      completionSubmitting,
      completionFn,
      importInputRef,
      onImportInputChange,
      importFileFn,
      importing,
      exportOrderSubmitting,
      exportOrderFn,
      AddExpressModalRef,
      addExpressFn,
      submitting,
      modalVisible,
      createExpressFn,
      loadingTypeFun,
      printStockClick,
      // tableResult,
      // onPageChange,
      // onSizeChange,
    }
  },
}
</script>

<style lang="less">
.DeliveryTable {
  .order-code {
    height: 40px;
    flex: 1;
    border-radius: 8px;
    background-color: #fafafa;
    color: #131011;
    font-size: 16px;
    padding: 0 16px 0 20px;

    img {
      display: inline-block;
      width: 16px;
      margin-right: 20px;
    }
  }
  .invoice-list {
    margin-bottom: 16px;
    // overflow: hidden;
    overflow-x: auto;
    .invoice-item,
    .invoice-add {
      height: 68px;
      padding: 14px 16px;
      border: 1px solid #fee5d4;
      border-radius: 4px;
      background-color: #fff5ec;
      line-height: 1;
      min-width: 140px;

      .time {
        color: #ff6700;
        font-weight: 400;
        font-size: 14px;
        white-space: nowrap;
      }

      .invoice-code {
        color: #131011;
        font-weight: 300;
        font-size: 14px;
      }

      .icon {
        width: 24px;
        margin-right: 12px;
      }

      .text {
        color: #ff6700;
        font-weight: 300;
        font-size: 14px;
      }
    }
    .invoice-add {
      display: flex;
      cursor: pointer;
    }
  }
  .ant-table-striped {
    background-color: #fff;
  }
}
</style>
